<template>
  <v-container class="mx-auto mx-lg-0">
    <v-row v-if="message" class="align-center mb-8">
      <v-col class="text-center">
        <v-card outlined max-width="960" elevation="12" class="mx-auto">
          <v-card-text>
            <h3 v-html="message"></h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="!showSurvey">
      <h2 class="text-center mb-10">Social Security Risk Score</h2>

      <v-row>
        <v-col class="px-md-10">
          <div class="mx-auto">
            <v-card max-width="800" class="mx-auto">
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  @submit.prevent="validate"
                  class="mt-2"
                >
                  <v-text-field
                    v-model="prospect.firstName"
                    :rules="requiredNameRules"
                    label="Client First Name"
                    outlined
                    required
                    background-color="white"
                  ></v-text-field>
                  <v-text-field
                    v-model="prospect.lastName"
                    :rules="requiredNameRules"
                    label="Client Last Name"
                    outlined
                    required
                    background-color="white"
                  ></v-text-field>
                  <!-- <v-text-field
                  v-model="prospect.email"
                  :rules="emailRules"
                  label="E-mail (REQUIRED)"
                  outlined
                  background-color="white"
                ></v-text-field>
                <v-text-field
                  v-model="prospect.phone"
                  :error-messages="phoneErrors"
                  v-maska="'###-###-####'"
                  label="Phone Number"
                  outlined
                  background-color="white"
                ></v-text-field> -->
                  <!-- <v-text-field
                  v-model="prospect.zip"
                  :rules="zipCodeRules"
                  label="Zip Code"
                  outlined
                ></v-text-field> -->
                  <div class="text-center">
                    <v-btn
                      :disabled="!valid"
                      x-large
                      color="primary"
                      class="mr-4"
                      type="submit"
                    >
                      Start
                    </v-btn>
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>

    <div
      ref="fullScreenScore"
      v-if="showSurvey && !message"
      class=""
      :style="fullscreen ? { background: '#212121', height: '100%' } : null"
      :class="fullscreen ? 'absolute d-flex flex-column px-14 pt-14' : ''"
    >
      <div class="mb-2">
        <v-row class="align-center">
          <v-col class="col-12 col-md-6">
            <div :class="fullscreen ? 'white--text' : ''">
              <h3>Social Security Risk Score Assessment</h3>
              <div v-if="prospectHasValues" class="text-body-2">
                <p class="mb-0">{{ prospect.firstName }} {{ prospect.lastName }}</p>
                <!-- <p class="mb-0">{{ prospect.email }}</p>
            <p class="mb-0">{{ prospect.phone }}</p> -->
              </div>
            </div>
          </v-col>
          <v-col class="d-flex justify-md-end col-12 col-md-6">
            <div class="">
              <v-btn color="primary" small type="button" @click="handleToggleFullscreen">
                <v-icon :small="!fullscreen ? true : false" class="mr-2"
                  >{{ !fullscreen ? "mdi-fullscreen" : "mdi-fullscreen-exit" }}
                </v-icon>
                {{ !fullscreen ? "Fullscreen" : "Exit Fullscreen" }}</v-btn
              >

              <v-btn
                v-if="!fullscreen"
                color="error"
                small
                outlined
                type="button"
                class="ml-4"
                @click="handleRestartSurvey"
              >
                <v-icon :small="!fullscreen ? true : false" class="mr-2"
                  >mdi-recycle</v-icon
                >
                Restart</v-btn
              >
            </div>
          </v-col></v-row
        >
      </div>
      <div style="border: 1px solid #212121">
        <RiskScoreAssessment
          :key="reloadKey"
          :advisorId="currentUser.id"
          :prospectId="null"
          :prospectContactData="prospectContactData"
          :externalReset="externalReset"
          source="advisor-account"
          reportOpenTarget="_blank"
          @handleConfirmSurveyReset="handleConfirmSurveyReset"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import { api as fullscreen } from "vue-fullscreen";
import RiskScoreAssessment from "@/components/RiskScoreAssessment.vue";
import { validatePhone } from "@/services/validatePhoneNumber";
import { mapGetters } from "vuex";

export default {
  components: {
    RiskScoreAssessment,
  },
  props: {
    advisorId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      externalReset: null,
      skipFormReset: false,
      reloadKey: 0,
      showSurvey: false,
      valid: false,
      fullscreen: false,
      teleport: false,
      pageOnly: false,
      message: null,
      phoneErrors: null,
      prospect: {
        firstName: null,
        lastName: null,
        // email: null,
        // zip: null,
        // phone: null,
      },
      nameRules: [(v) => !v || v.length >= 2 || "Name must be at least 3 characters"],
      requiredNameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 2) || "Name must be at least 3 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      // phoneRules: [
      //   (v) => (v && this.handleValidatePhone(v)) || "Enter a valid phone number.",
      // ],
      // zipCodeRules: [
      //   (v) => !!v || "Zip Code is required",
      //   (v) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || "Please enter a valid zip code.",
      // ],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    prospectHasValues() {
      function hasTruthyValues(obj) {
        return Object.values(obj).some((v) => Boolean(v));
      }
      return hasTruthyValues(this.prospect);
    },
    prospectContactData() {
      const obj = {
        name: {
          firstName: this.prospect.firstName,
          lastName: this.prospect.lastName,
        },
        // email: this.prospect.email,
        // phone: this.prospect.phone,
      };
      return JSON.stringify(obj);
    },
  },
  watch: {
    prospect: {
      async handler(val) {
        if (val.phone) {
          const isValidPhone = await this.handleValidatePhone(val.phone);

          if (!isValidPhone) {
            this.phoneErrors = "Enter a valid phone number";
          }
        } else {
          this.phoneErrors = null;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    console.log("WE BE MOUNTED IN URSR");
    // remove any previously stored risk score assessment info
  },
  // beforeDestroy() {
  //   console.log("beforeDestroy UserRunScoreReport");
  // },
  methods: {
    reload() {
      this.reloadKey++; // Increment the reload key to force a re-render
    },
    validate() {
      this.$refs.form.validate();

      if (this.valid) {
        // this.reload();
        this.showSurvey = true;
      }
    },
    async handleValidatePhone(num) {
      try {
        // validate phone number
        const response = await validatePhone(num);

        if (response.name === "Error") {
          throw new Error(response.message);
        } else {
          return true;
        }
      } catch (errorMsg) {
        console.log("validate phone error", errorMsg);

        // this.message = `
        //       <p><strong>${errorMsg}</strong></p>
        //       <p class="error--text"><strong>${num}</strong></p>
        //       <p>Please enter a valid US phone number for the advisor.</p>`;

        return false;
      }
    },
    handleResetProspect() {
      this.prospect = {
        firstName: null,
        lastName: null,
        // email: null,
        // phone: null,
      };
    },
    async handleToggleFullscreen() {
      await fullscreen.toggle(this.$refs.fullScreenScore, {
        teleport: this.teleport,
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
      });
      this.fullscreen = fullscreen.isFullscreen;
    },
    handleRestartSurvey() {
      // this.handleResetProspect();
      // this.showSurvey = false;
      // This will trigger the survey to reset its store and send an event
      // that calls handleConfirmSurveyReset
      console.log("External reset in handlereesrtarsurvey", this.externalReset);

      // this.externalReset = true;
      // this.showSurvey = false;
      // this.reload();
      // remove any previously stored risk score assessment info
      // localStorage.removeItem("rs-pid");
      // localStorage.removeItem("rs-answers");
      // this.reloadKey++; // Increment key to force re-creation of the component
      // this.$forceUpdate();
      this.$router.go();
    },
    handleConfirmSurveyReset(e) {
      console.log("SURVEY EVENT RECEIVED IN PARENT handleConfirmSurveyReset", e);
      if (e.reset) {
        this.handleResetProspect();
        this.showSurvey = false;
        this.externalReset = false;
        // if (this.skipFormReset) {
        //   this.handleResetProspect();
        //   this.showSurvey = true;
        // } else {
        //   this.showSurvey = false;
        // }
        // this.skipFormReset = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
