<template>
  <div ref="componentRef">
    <div v-if="loading" class="d-flex flex-column align-center justify-center mt-12">
      <h3 class="mb-8">Setting up assessment...</h3>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <plangap-risk-score
        :env="env"
        :advisor-id="advisorId ? advisorId : ''"
        :prospect-id="prospectId ? prospectId : ''"
        :prospect-contact-data="prospectContactData"
        :report-open-target="reportOpenTarget"
        :id="advisorId ? advisorId : ''"
        :vanity="vanityName ? vanityName : ''"
        :externalReset="externalReset"
        :source="`rs-${source}`"
      ></plangap-risk-score>
    </div>
  </div>
</template>

<script>
export default {
  name: "RiskScoreAssessment",
  props: {
    externalReset: {
      type: Boolean,
    },
    advisorId: {
      type: String,
    },
    prospectId: {
      type: String,
    },
    prospectContactData: {
      type: String,
    },
    vanityName: {
      type: String,
    },
    reportOpenTarget: {
      type: String,
    },
    source: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      env: null,
    };
  },
  computed: {
    surveySource() {
      if (this.source) {
        return this.source;
      } else if (this.env) {
        return this.env;
      } else {
        return "not-set";
      }
    },
  },
  created() {
    this.env = process.env.VUE_APP_SCORE_ENV;
  },
  mounted() {
    console.log("WE BE MOUNTED IN RSA externalReset", this.externalReset);
    document.addEventListener("update", (ev) => {
      this.handleConfirmReset(ev.detail);
    });

    let surveyEnv;
    if (this.env && this.env === "production") {
      surveyEnv = ""; // bc prod survey bucket is: pgriskscoresurvey with no env at end of string
    } else {
      surveyEnv = this.env;
    }
    const existingPolyfillScriptTag = document.getElementById("webComponentPolyfill");

    if (!existingPolyfillScriptTag) {
      let webComponentPolyfill = document.createElement("script");

      webComponentPolyfill.setAttribute(
        "src",
        "https://unpkg.com/@webcomponents/webcomponentsjs@latest/webcomponents-loader.js"
      );
      webComponentPolyfill.setAttribute("id", "webComponentPolyfill");

      document.head.appendChild(webComponentPolyfill);
    }

    const existingScoreSurveyScriptTag = document.getElementById("scoreSurveyScript");

    if (!existingScoreSurveyScriptTag) {
      let scoreSurveyScript = document.createElement("script");

      scoreSurveyScript.setAttribute(
        "src",
        `https://pgriskscoresurvey${surveyEnv}.s3.amazonaws.com/dist/plangap-risk-score.min.js`
      );
      scoreSurveyScript.setAttribute("id", "scoreSurveyScript");

      document.head.appendChild(scoreSurveyScript);
    }

    this.loading = false;
  },
  methods: {
    handleConfirmReset(e) {
      this.$emit("handleConfirmSurveyReset", e);
    },
  },
  // beforeDestroy() {
  //   console.log("BEFORE DESTROY!!!!");
  //   // Remove score survey from dom when destroy component or the previous data will persist
  //   const existingScoreSurveyScriptTag =
  //     document.getElementById("scoreSurveyScript");
  //   if (existingScoreSurveyScriptTag) {
  //     document.head.removeChild(existingScoreSurveyScriptTag);

  //     // existingScoreSurveyScriptTag.parentNode.removeChild(
  //     //   existingScoreSurveyScriptTag
  //     // );
  //   }
  //   console.log(
  //     "BEFORE DESTROY!!!!222",
  //     document.getElementById("scoreSurveyScript")
  //   );
  // },
};
</script>

<style lang="scss" scoped></style>
